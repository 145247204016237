
import { CompanyInfoCurrent, Events } from "@/api";
import { CurrentCompanyInfo } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: { ValidationProvider, ValidationObserver, PageTitle }
})
export default class CompanyInfo extends Vue {
  headersTitle = [{ icon: "mdi-border-color", header: "Edit Company Info" }];
  companyInfo: CurrentCompanyInfo = {
    address: ""
  };
  currentId: number | null = null;
  async created() {
    const adr = await Events.getOfficeAddress();
    this.currentId = adr.data[0].id;
    this.companyInfo.address = adr.data[0].address;
  }
  async save() {
    try {
      if (this.currentId) {
        await CompanyInfoCurrent.submitCompanyInfo(
          this.companyInfo,
          this.currentId
        );
        await this.$success(
          "<strong>Success!</strong> Company Info has been updated!"
        );
      } else {
        await CompanyInfoCurrent.createCompanyInfo(this.companyInfo);
        await this.$success(
          "<strong>Success!</strong> Company Info has been created!"
        );
      }
    } catch (e) {
      (this.$refs.companyForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(e.errors);
      await this.$error(e.errors);
    }
  }
}
